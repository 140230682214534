.pswp__caption__center {
  text-align: center !important; }

#gallery {
  border: 1px solid #fff; }
  #gallery .item {
    box-sizing: border-box;
    position: relative;
    width: 50%;
    cursor: pointer;
    border: 1px solid #fff;
    background-color: #eee; }
    #gallery .item .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      transition: opacity 1.5s ease;
      opacity: 0; }
    #gallery .item.loaded .img {
      opacity: 1; }
    #gallery .item:hover {
      -webkit-animation: flash 2s;
              animation: flash 2s; }
    @media (min-width: 900px) {
      #gallery .item {
        width: 33.33333%; } }
    @media (min-width: 1200px) {
      #gallery .item {
        width: 25%; } }
    @media (min-width: 1400px) {
      #gallery .item {
        width: 20%; } }
    #gallery .item::after {
      content: '';
      display: block; }
    #gallery .item.l::after {
      padding-bottom: 66.666666666666667%; }
    #gallery .item.p::after {
      padding-bottom: 150%; }
    #gallery .item.force-p::after {
      padding-bottom: 150%; }

@-webkit-keyframes flash {
  0% {
    opacity: 0.8; }
  100% {
    opacity: 1; } }

@keyframes flash {
  0% {
    opacity: 0.8; }
  100% {
    opacity: 1; } }
