html {
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; }

body {
  font-family: 'Poppins', sans-serif;
  color: #69655a;
  height: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; }

header {
  background: url(img/4ccc604d7eba94387f1467a1835f4b7b.jpg) no-repeat 70% 20%;
  background-size: cover;
  font-size: 5px;
  line-height: 1;
  transition: all 0.5s;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center; }
  @media (min-width: 350px) and (min-height: 480px) {
    header {
      font-size: 7px; } }
  @media (min-width: 480px) and (min-height: 490px) {
    header {
      font-size: 9px; } }
  @media (min-width: 768px) and (min-height: 600px) {
    header {
      font-size: 10px; } }
  @media (min-width: 992px) and (min-height: 500px) {
    header {
      font-size: 9px; } }
  @media (min-width: 992px) and (min-height: 550px) {
    header {
      font-size: 12px; } }
  @media (min-width: 1200px) and (min-height: 650px) {
    header {
      font-size: 14px; } }
  header::before {
    top: 0;
    left: 0;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #000;
    opacity: 0.3;
    z-index: 1; }
  header > * {
    flex-shrink: 0;
    flex-grow: 0; }
  header div {
    position: relative;
    z-index: 2; }

#nav {
  display: none;
  position: absolute;
  width: 25px;
  height: 19px;
  top: 15px;
  right: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer; }
  #nav span {
    position: absolute;
    left: 0;
    display: block;
    height: 3px;
    width: 100%;
    background-color: #fff;
    border-radius: 9px;
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: all 0.25s; }
  #nav > span:nth-child(1) {
    top: 0; }
    .main-active #nav > span:nth-child(1) {
      top: 7px;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg); }
  #nav > span:nth-child(2) {
    top: 7px; }
    .main-active #nav > span:nth-child(2) {
      top: -40;
      opacity: 0; }
  #nav > span:nth-child(3) {
    top: 14px; }
    .main-active #nav > span:nth-child(3) {
      top: 7px;
      -webkit-transform: rotate(-135deg);
              transform: rotate(-135deg); }

#couple {
  margin-top: 0.7em;
  font-family: 'Clicker Script', sans-serif;
  font-size: 8.25em;
  color: #fff;
  white-space: nowrap;
  margin-bottom: 0.2em; }
  #couple > span {
    color: #e2b123; }

#date {
  font-size: 2.5em;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0.5em; }
  #date > span {
    color: #e2b123; }
  #date::before, #date::after {
    position: relative;
    top: 0.25em;
    display: inline-block;
    width: 2.75em;
    height: 1em;
    content: '';
    background-repeat: no-repeat;
    background-size: contain; }
  #date::before {
    margin-right: 0.5em;
    background-image: url(img/c4af97bb6e52935b2f753ed9ff587be7.png);
    background-position: center right; }
  #date::after {
    margin-left: 0.5em;
    background-image: url(img/84c30a51280d8e174f2c6d3d5368a769.png);
    background-position: center left; }

#more {
  margin-top: auto;
  -webkit-transform-origin: bottom;
          transform-origin: bottom; }
  #more.animate {
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: bounce;
            animation-name: bounce;
    -webkit-animation-duration: 6s;
            animation-duration: 6s;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
            animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }
  #more span {
    text-align: center;
    white-space: nowrap;
    transition: all 0.5s;
    display: inline-block;
    color: #fff;
    background-color: transparent;
    margin-top: 6px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 175%;
    cursor: pointer;
    outline: none;
    border: none; }
    #more span:hover {
      color: #e2b123;
      outline: none;
      box-shadow: none; }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  3.33% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0); }
  10% {
    -webkit-transform: scale(0.9, 1.1) translateY(-100px);
            transform: scale(0.9, 1.1) translateY(-100px); }
  16.66% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
            transform: scale(1.05, 0.95) translateY(0); }
  19% {
    -webkit-transform: scale(1, 1) translateY(-7px);
            transform: scale(1, 1) translateY(-7px); }
  21.33% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  3.33% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0); }
  10% {
    -webkit-transform: scale(0.9, 1.1) translateY(-100px);
            transform: scale(0.9, 1.1) translateY(-100px); }
  16.66% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
            transform: scale(1.05, 0.95) translateY(0); }
  19% {
    -webkit-transform: scale(1, 1) translateY(-7px);
            transform: scale(1, 1) translateY(-7px); }
  21.33% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }
