body {
  overflow: hidden; }
  body.ready {
    overflow-y: auto; }

#preload {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  color: #e2b123;
  height: 100%;
  width: 100%;
  text-align: center;
  transition: opacity 2s;
  transition-delay: 0.5s;
  pointer-events: none;
  background: #fff; }
  #preload .heart-group {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;
    font-size: 2em;
    margin-top: -1em; }
    #preload .heart-group span {
      opacity: 0.2;
      -webkit-animation: fadeInOut 1.5s infinite;
              animation: fadeInOut 1.5s infinite; }
      #preload .heart-group span:nth-child(2) {
        -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s; }
      #preload .heart-group span:nth-child(3) {
        -webkit-animation-delay: 0.6s;
                animation-delay: 0.6s; }
      #preload .heart-group span:nth-child(4) {
        -webkit-animation-delay: 0.9s;
                animation-delay: 0.9s; }
  #preload.hide {
    opacity: 0; }
    #preload.hide .heart-group {
      transition: opacity 1s;
      transition-delay: 0.5s;
      opacity: 0; }
  #preload.remove {
    display: none; }

@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }

@keyframes fadeInOut {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }
